<template>
  <div>
    <div class="bodyer">
      <div class="main-context">
        <div class="bodyer-box">
          <div class="contact">
            <div class="contact-title">
              联系我们
              <div>Contact Us</div>
            </div>
            <div class="contact-content">
              <div>江苏央苗供应链管理有限公司</div>
              <div>
                <span>地址：</span>江苏省南京市雨花台区证大喜马拉雅中心K座1307座
              </div>
              <div><span>邮编：</span>211500</div>
              <div><span>客服：</span>400-0000-0000</div>
            </div>
            <img src="../assets/images/截屏2021-01-25 下午3.44.07@2x.png">
          </div>
        </div>
        <div class="bodyer-box">
          <div class="business">
            <div class="business-title">
              业务合作
              <div>Business Collaboration</div>
            </div>
            <div class="business-content">
              <div class="business-items">
                <div class="business-item">
                  <img src="../assets/images/图标-1@2x.png">
                  <div class="bus-name">
                    品牌商务合作
                  </div>
                  <div class="bus-email">
                    marketing-bd@lixianggo.com
                  </div>
                </div>
                <div class="business-item">
                  <img src="../assets/images/图标-2@2x.png">
                  <div class="bus-name">
                    企业团购/大客户销售
                  </div>
                  <div class="bus-email">
                    sales@lixianggo.com
                  </div>
                </div>
                <div class="business-item">
                  <img src="../assets/images/图标-3@2x.png">
                  <div class="bus-name">
                    市场合作
                  </div>
                  <div class="bus-email">
                    tec@lixianggo.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bodyer-box">
          <div class="product">
            <div class="product-title">
              商品招商联系方式
              <div>Product Business Contact</div>
            </div>
            <div class="product-content">
              <div class="product-items">
                <div class="product-item">
                  <img src="../assets/images/图标-生鲜@2x.png">
                  <div class="product-name">
                    生鲜类
                  </div>
                  <div class="product-email">
                    sx@lixianggo.com
                  </div>
                </div>
                <div class="product-item">
                  <img src="../assets/images/图标-粮油@2x.png">
                  <div class="product-name">
                    粮油类
                  </div>
                  <div class="product-email">
                    ly@lixianggo.com
                  </div>
                </div>
                <div class="product-item">
                  <img src="../assets/images/图标-零食@2x.png">
                  <div class="product-name">
                    零食类
                  </div>
                  <div class="product-email">
                    ls@lixianggo.com
                  </div>
                </div>
                <div class="product-item">
                  <img src="../assets/images/图标-酒饮@2x.png">
                  <div class="product-name">
                    酒饮类
                  </div>
                  <div class="product-email">
                    jy@lixianggo.com
                  </div>
                </div>
                <div class="product-item">
                  <img src="../assets/images/图标-日百@2x.png">
                  <div class="product-name">
                    日百类
                  </div>
                  <div class="product-email">
                    rb@lixianggo.com
                  </div>
                </div>
                <div class="product-item">
                  <img src="../assets/images/图标-其他@2x.png">
                  <div class="product-name">
                    其他
                  </div>
                  <div class="product-email">
                    other@lixianggo.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
  .bodyer {
    padding-top: 70px;

    .bodyer-box {
      padding: 0 80px;
      margin-bottom: 120px;

      .contact {
        img {
          width: 100%;
          height: auto;
        }

        .contact-title {
          font-size: 36px;
          font-weight: bold;
          color: #17254c;
          margin-bottom: 45px;
          border-bottom: solid 1px #efeff1;

          div {
            font-size: 24px;
            font-weight: 400;
            color: #17254c;
            opacity: 0.2;
          }
        }

        .contact-content {
          margin-bottom: 20px;
          div {
            font-weight: 400;
            color: #17254c;
            margin-bottom: 5px;

            span {
              font-weight: 400;
              color: #17254c;
              opacity: 0.5;
            }
          }
        }
      }

      .business {
        .business-title {
          font-size: 36px;
          font-weight: bold;
          color: #17254c;
          margin-bottom: 45px;
          border-bottom: solid 1px #efeff1;

          div {
            font-size: 24px;
            font-weight: 400;
            color: #17254c;
            opacity: 0.2;
          }
        }

        .business-content {
          margin-bottom: 20px;

          .business-items {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: flex-start;
            align-items: flex-start;
            .business-item {
              flex: 1;
              text-align: center;

              img {
                width: 60px;
                height: 60px;
                margin-bottom: 10px;
              }
              .bus-name {
                margin-bottom: 5px;
                font-weight: bold;
                color: #17254c;
              }
              .bus-email {
                font-weight: 400;
                color: #17254c;
                opacity: 0.9;
              }
            }
          }
        }
      }

      .product {
        .product-title {
          font-size: 36px;
          font-weight: bold;
          color: #17254c;
          margin-bottom: 45px;
          border-bottom: solid 1px #efeff1;

          div {
            font-size: 24px;
            font-weight: 400;
            color: #17254c;
            opacity: 0.2;
          }
        }

        .product-content {
          margin-bottom: 20px;
          .product-items {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: flex-start;
            align-items: flex-start;
            .product-item {
              flex: 0 0 33.33%;
              text-align: center;
              margin-bottom: 20px;

              img {
                width: 80px;
                height: 80px;
              }
              .product-name {
                margin-bottom: 5px;
                font-weight: bold;
                color: #17254c;
              }
              .product-email {
                font-weight: 400;
                color: #17254c;
                opacity: 0.9;
              }
            }
          }
        }
      }
    }
  }
</style>
